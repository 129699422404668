<!-- 小组列表页-->
<template>
  <div class="groupObjectList">
    <!-- 头部 -->
    <groupObjectListTop
      :totalSize="totalSize"
      :isactiveLabel="isactiveLabel"
      @searchEnter="searchEnter"
      @addNewGroup="addNewGroup"
    />
    <!-- 内容部分 -->
    <div class="groupObjectList_content">
      <!-- 内容部分左侧 -->
      <div class="content_left">
        <div class="top_title_group">
          <!-- 小组 -->
          <div>{{ $t("chatter.group.name") }}</div>
        </div>
        <div class="all_menus_group">
          <div
            class="each_menu"
            v-for="(item, index) in groupOptions"
            :key="index"
            :class="{ active: isactive == item.value }"
            @click="getIsClickedGroup(item)"
          >
            {{ item.label }}
          </div>
        </div>
      </div>
      <!-- 内容部分右侧 -->
      <groupObjectListRightTable
        :allGroupDataArr="allGroupDataArr"
        :userId="userId"
        :totalnum="totalSize"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        @editGroupDetails="editGroupDetails"
        @deleteGroup="deleteGroup"
        :loading="loading"
      />
      <!-- 新建小组弹框 -->
      <AddGroup
        :addGroupTo="addGroupTo"
        :username="username"
        @addGroupCancel="addGroupCancel"
        @addGroupConfirm="addGroupConfirm"
      />
      <!-- 上传小组头像弹框 -->
      <UploadPicture
        :uploadPictureTo="uploadPictureTo"
        @uploadPictureCancel="uploadPictureCancel"
        @uploadPictureConfirm="uploadPictureConfirm"
        :newGroupId="newAddedGroupId"
      />
      <!-- 成员管理 -->
      <MembersManage
        :membersManageTo="membersManageTo"
        @loadMore="loadMore"
        :newGroupId="newAddedGroupId"
        @clearKeyWord="clearKeyWord"
        :queryUserResData="queryUserResData"
        :createdUserId="queryUserId"
        :userListData="userListData"
        :createdUserIdGroup="queryGroupManagerUserId"
        @getNewPerson="getNewPerson"
        @membersManageCancel="membersManageCancel"
        @membersManageConfirm="membersManageConfirm"
      />
      <!-- 编辑小组 -->
      <eidtGroup
        :eidtGroupTo="eidtGroupTo"
        :nowGroupName="nowGroupName"
        :nowGroupDescription="nowGroupDescription"
        :nowGroupGroupType="nowGroupGroupType"
        :username="username"
        @editGroupCancel="editGroupCancel"
        @editGroupConfirm="editGroupConfirm"
      />
      <!-- 删除小组 -->
      <DelThisGroup
        :delThisGroupTo="delThisGroupTo"
        :groupName="groupDelName"
        @delThisGroupCancel="delThisGroupCancel"
        @delThisGroupConfirm="delThisGroupConfirm"
      />
    </div>
  </div>
</template>

<script>
/**
 * 小组对象
 */
//api
import {
  queryUserGroups, //获取CCChat小组列表
  addUserGroupInfo, //新增CCChat小组
  uploadGroupImage, //上传小组头像
  queryUser, //查询CCChat所有用户
  queryGroupManager, //查询CCChat小组成员
  updateUserGroupInfo, //更新CCChat小组
  deleteUserGroup, //删除小组
  // queryUserGroupInfo,
} from "../api.js";

import groupObjectListTop from "./components/groupObjectListTop";
import groupObjectListRightTable from "./components/groupObjectListRightTable";
import AddGroup from "@/views/colleagueCircle/components/dynamic-groupList/AddGroup";
import UploadPicture from "@/views/colleagueCircle/components/dynamic-groupList/UploadPicture";
import MembersManage from "@/views/colleagueCircle/components/dynamic-groupList/MembersManage";
import eidtGroup from "./components/eidtGroup";
import DelThisGroup from "@/views/colleagueCircle/components/dynamic-message/DelThisGroup";
export default {
  components: {
    groupObjectListTop,
    groupObjectListRightTable,
    AddGroup,
    UploadPicture,
    MembersManage,
    eidtGroup,
    DelThisGroup,
  },
  data() {
    return {
      username: "", //所有人的姓名
      isactive: "2", //被选中添加样式,
      isactiveLabel: "", //被选中的label,
      groupType: "recent", //查看的小组类型（默认为"最近查看"）
      totalSize: 0, //小组总数量
      allGroupDataArr: [], //小组的详细信息
      userId: "", //当前登录用户的ID
      isStatus: "", //当前登录用户与小组的关系(之与小组的状态)
      groupOptions: [
        {
          value: "1",
          label: this.$i18n.t("Dashboard_View_RecentlyView"), //最近查看
        },
        {
          value: "2",
          label: this.$i18n.t("label_chatter_mygroups"), //我的小组
        },
        {
          value: "3",
          label: this.$i18n.t("vue_label_chatter_all_groups"), //"所有小组"
        },
      ],
      keyWordsContent: "", //搜索内容
      pageSize: "10", //列表每页数量
      pageNum: "1", //列表当前页数
      addGroupTo: false, //新建小组弹框
      formName: {}, // 新建小组时的表单数据
      newAddedGroupId: "", //新建小组后得到的'新小组'的小组ID
      uploadPictureTo: false, //上传小组头像弹框
      uploadObj: "", //上传头像时需要的对象 {图片格式,小组ID}
      dataFile: "", //头像文件
      keyword: "", //搜索的关键词
      pageUserNum: "1", //成员管理框Num
      pageUserSize: "10", //成员管理框Size
      queryUserResData: [], //CCChat所有用户
      queryUserId: "", //CCChat所有用户中 -- 当前登录用户ID
      userListData: [], //CCChat小组成员
      queryGroupManagerUserId: "", //CCChat小组成员 -- 当前登录用户ID
      membersManageTo: false, //成员管理弹框
      nowGroupName: "", // 编辑前的小组名称
      nowGroupDescription: "", // 编辑前的小组备注
      nowGroupGroupType: "", // 编辑前的小组类型
      nowGroupOwnerId: "", // 编辑前的小组所有人ID
      nowGroupId: "", //编辑前的小组ID
      eidtGroupTo: false, //编辑小组弹框
      nowForm: "", //编辑前的小组信息对象
      nowIsDeletingGroup: "", //将要被删除的小组的ID
      delThisGroupTo: false, //删除小组弹框
      groupDelName: "", //将被删除的小组的name
      loading: false, // 小组列表加载loading
      groupInfoList: {},
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.getIsClickedGroup({
      value: "2",
      label: this.$i18n.t("label_chatter_mygroups"),
    });
    //获取小组列表信息
    this.getQueryUserGroups();
    this.$bus.$on("getQueryUserGroupsBus",this.ebFn1 );
    //获取 'CCChat所有用户' and 'CCChat小组成员'
    this.$bus.$on("getGroupMembers",this.ebFn2 );
  },
  destroyed() {
    this.$bus.$off("getQueryUserGroupsBus",this.ebFn1);
    this.$bus.$off("getGroupMembers",this.ebFn2);
  },
  methods: {
    ebFn1(){
      this.getQueryUserGroups();
    },
    ebFn2(){
      this.uploadPictureConfirm(this.uploadObj, this.dataFile);
    },
    //点击 ( 最近查看 -- 我的小组 -- 所有小组 )
    getIsClickedGroup(item) {
      this.isactiveLabel = item.label;
      this.isactive = item.value;
      switch (item.value) {
        case "1":
          this.groupType = "recent";
          break;
        case "2":
          this.groupType = "ug_myGroup";
          break;
        case "3":
          this.groupType = "all";
          break;
      }
      this.getQueryUserGroups();
    },
    // 获取小组列表信息
    async getQueryUserGroups() {
      this.loading = true;
      let queryUserGroupsRes = await queryUserGroups({
        keyword: this.keyWordsContent, //搜索内容
        groupType: this.groupType,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
      });
      this.totalSize = queryUserGroupsRes.data.totalSize; //小组总数量
      this.allGroupDataArr = queryUserGroupsRes.data.list; //小组信息
      // this.allGroupDataArr.map(async (item) => {
      //   await this.getQueryUserGroupInfo(item._id);
      //   this.$set(this.allGroupDataArr, "groupInfoList", this.groupInfoList);
      // });
      // 
      this.userId = queryUserGroupsRes.data.userId; //当前登录用户的ID

      /** 当前登录用户与小组关系分析
       *  1.如果当前登录用户的ID === 小组所有人ID (所有人)
       *  2.小组为公用时,当前登录用户的ID 中有=== 小组成员ID 且成员状态 userstatus 为 normal(正常)时 ==> (退出 小组),反之申请加入
       *                 当前登录用户的ID 中有=== 小组成员ID 且成员状态 userstatus 为 apply(申请)时 ==> 取消申请
       *  3.小组为私用时,当前登录用户的ID 中有=== 小组成员ID 且成员状态 userstatus 为 normal(正常)时 ==> (退出小组),
       */

      /* 遍历小组列表拿到每个小组的详细信息
         isStatus--给每个小组添加一个标识代表 当前用户与该小组的关系
      */
      queryUserGroupsRes.data.list.map((item) => {
        if (item.ownerId === this.userId) {
          //所有人
          item.isStatus = this.$i18n.t("label.referperson");
        }
        //小组为公用时
        else if (item.groupType === "public") {
          //遍历小组成员
          item.groupusers.filter((item1) => {
            //当前登录用户的ID 中有=== 小组成员ID
            if (item1.userid == this.userId) {
              //退出小组
              item.isStatus = this.$i18n.t("label_tabpage_exitz");
            } else {
              //申请加入
              item.isStatus = this.$i18n.t("chatter.group.applyToJoin");
            }
          });
          //遍历申请成员
          item.applyUser.filter((item2) => {
            // 当前登录用户的ID 中有=== 申请成员ID 且成员状态 userstatus 为 apply(申请)
            if (item2.userid == this.userId && item2.userstatus === "apply") {
              //申请加入
              item.isStatus = this.$i18n.t("chatter.group.cancelRequest");
            }
          });
        }
        //小组为私用时
        else if (item.groupType === "private") {
          item.groupusers.filter((item3) => {
            // 当前登录用户的ID 中有=== 小组成员ID
            if (item3.userid === this.userId) {
              //退出小组
              item.isStatus = this.$i18n.t("label_tabpage_exitz");
            }
          });
        }
      });
      this.$forceUpdate();
      this.loading = false;
    },
    //获取小组的详细信息
    // async getQueryUserGroupInfo(id) {
    //   let groupInfoRes = await queryUserGroupInfo({
    //     groupId: id,
    //   });
    //   this.groupInfoList = groupInfoRes.data.list[0];
    // },
    // 关键词搜索
    searchEnter(value) {
      this.keyWordsContent = value;
      this.getQueryUserGroups();
    },
    // 小组列表每页展示数量
    handleSizeChange(val) {
      this.pageSize = val;
      this.getQueryUserGroups();
    },
    // 小组列表当前展示的页数
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getQueryUserGroups();
    },
    // 新建小组弹框
    addNewGroup() {
      this.addGroupTo = true;
      this.username = localStorage.getItem("username");
    },
    // 关闭或取消
    addGroupCancel(formName) {
      this.addGroupTo = false;
      this.formName = formName;
      this.formName.groupName = "";
      this.formName.desc = "";
      this.formName.resource = "";
    },
    // 确定新建小组 --> 上传头像
    async addGroupConfirm(formName) {
      this.addGroupTo = false;
      this.formName = formName;
      let addUserGroupInfoRes = await addUserGroupInfo({
        name: formName.groupName,
        description: formName.desc,
        groupType: formName.resource,
      });
      this.newAddedGroupId = addUserGroupInfoRes.data.id;
      this.formName.groupName = "";
      this.formName.desc = "";
      this.formName.resource = "";
      this.uploadPictureTo = true;
    },
    // 上传头像弹框关闭或取消
    uploadPictureCancel() {
      this.uploadPictureTo = false;
      this.formName.groupName = "";
      this.formName.desc = "";
      this.formName.resource = "";
      this.getQueryUserGroups();
    },
    // 确定上传头像 --> 成员管理弹框
    async uploadPictureConfirm(uploadObj, dataFile) {
      this.uploadPictureTo = false;
      this.uploadObj = uploadObj;
      this.dataFile = dataFile;
      let formData = new FormData();
      // 未上传图片时不调用上传头像接口
      if (JSON.stringify(dataFile) !== "{}") {
        formData.append("file", dataFile);
        formData.append("contJson", JSON.stringify(uploadObj));
        //上传小组头像
        await uploadGroupImage(formData);
      }
      //查询CCChat所有用户
      let queryUserRes = await queryUser({
        groupId: this.newAddedGroupId,
        keyword: this.keyword,
        pageNum: this.pageUserNum,
        pageSize: this.pageUserSize,
      });
      this.queryUserResData = queryUserRes.data.user_l;
      this.queryUserId = queryUserRes.data.userId;
      //查询CCChat小组成员
      let queryGroupManagerRes = await queryGroupManager({
        groupId: this.newAddedGroupId,
        mark: "all",
        keyword: this.keyword,
        pageNum: this.pageUserNum,
        pageSize: this.pageUserSize,
      });
      this.userListData = queryGroupManagerRes.data.userList;
      this.queryGroupManagerUserId = queryGroupManagerRes.data.userId;
      this.formName.groupName = "";
      this.formName.desc = "";
      this.formName.resource = "";
      this.membersManageTo = true;
    },
    // 成员管理弹框关闭或取消
    membersManageCancel() {
      this.membersManageTo = false;
      this.formName.groupName = "";
      this.formName.desc = "";
      this.formName.resource = "";
    },
    //滚动加载
    loadMore() {
      this.pageUserSize = Number(this.pageUserSize) + 5;
      this.uploadPictureConfirm(this.uploadObj, this.dataFile);
    },
    //搜索
    getNewPerson(newPersonKeyword) {
      this.keyword = newPersonKeyword;
      this.uploadPictureConfirm(this.uploadObj, this.dataFile);
    },
    //清空关键字
    clearKeyWord() {
      this.keyword = "";
    },
    //完成成员管理弹框
    membersManageConfirm() {
      this.membersManageTo = false;
      this.getQueryUserGroups();
      this.formName.groupName = "";
      this.formName.desc = "";
      this.formName.resource = "";
    },
    //编辑小组
    editGroupDetails(name, description, groupType, ownerId, id) {
      this.username = localStorage.getItem("username");
      this.nowGroupName = name;
      this.nowGroupDescription = description;
      this.nowGroupGroupType = groupType;
      this.nowGroupOwnerId = ownerId;
      this.nowGroupId = id;
      this.eidtGroupTo = true;
    },
    // 取消编辑
    editGroupCancel(formName) {
      this.nowForm = formName;
      this.eidtGroupTo = false;
      this.nowForm.groupName = this.nowGroupName;
      this.nowForm.desc = this.nowGroupDescription;
      this.nowForm.resource = this.nowGroupGroupType;
    },
    // 确定编辑小组
    async editGroupConfirm(formName) {
      this.nowForm = formName;
      this.eidtGroupTo = false;
      //更新小组信息接口
      await updateUserGroupInfo({
        name: this.nowForm.groupName,
        description: this.nowForm.desc,
        groupType: this.nowForm.resource,
        ownerId: this.nowGroupOwnerId,
        groupId: this.nowGroupId,
      });
      this.getQueryUserGroups();
      this.nowForm.groupName = this.nowGroupName;
      this.nowForm.desc = this.nowGroupDescription;
      this.nowForm.resource = this.nowGroupGroupType;
    },
    // 删除小组弹框打开
    deleteGroup(name, id) {
      this.groupDelName = name;
      this.nowIsDeletingGroup = id;
      this.delThisGroupTo = true;
    },
    //取消或关闭 删除小组
    delThisGroupCancel() {
      this.delThisGroupTo = false;
    },
    // 确定 删除小组
    async delThisGroupConfirm() {
      this.delThisGroupTo = false;
      let deleteUserGroupRes = await deleteUserGroup({
        groupId: this.nowIsDeletingGroup,
      });
      if (deleteUserGroupRes.result == true) {
        this.$message.success(this.$i18n.t("label_tabpage_delsuccessz"));
        this.getQueryUserGroups();
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.groupObjectList {
  height: 100%;
  padding: 10px;
  font-size: 12px !important;
  color: #080707 !important;
  display: flex;
  flex-direction: column;
  .groupObjectList_content {
    background-color: #ffffff;
    height: calc(100% - 70px);
    padding: 10px;
    display: flex;
    justify-content: space-between;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    // 内容左侧样式
    .content_left {
      background-color: #fff;
      border: 1px solid rgb(220, 220, 220);
      width: 240px;
      .active {
        color: #006dcc;
      }
      .top_title_group {
        display: flex;
        align-items: center;
        background: #f3f2f2;
        padding: 11px 20px 10px 17px;
        i {
          margin-left: 10px;
          cursor: pointer;
        }
      }
      .all_menus_group {
        .each_menu {
          padding: 11px 20px 10px 17px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>