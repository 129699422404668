var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"groupObjectListRightTable"},[_c('div',{staticClass:"list_table"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.allGroupDataArr,"height":"calc(100% - 66px)"}},[_c('el-table-column',{attrs:{"label":_vm.$t('chatter.group.groupName'),"min-width":"80","align":"left","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"groupContent"},[_c('div',{staticClass:"groupImg"},[_c('img',{attrs:{"src":(_vm.imgUrlCookie + "/chatterGroup.action?m=showGroupImage&id=" + (scope.row._id) + "&binding=" + _vm.tokenCookie),"alt":""}})]),_c('div',{staticClass:"groupWord"},[_c('div',{staticClass:"word_style"},[_c('span',{class:scope.row.isStatus === _vm.$t('label.referperson') ||
                    scope.row.isStatus === _vm.$t('label_tabpage_exitz')
                      ? ''
                      : 'groupNameClass',on:{"click":function($event){return _vm.jumpGroupDetails(scope.row.isStatus, scope.row._id)}}},[_vm._v(_vm._s(scope.row.name))]),_vm._v(" ("+_vm._s(scope.row.groupTypeValue)+") ")]),_c('div',{staticClass:"word_style"},[_vm._v(" "+_vm._s(scope.row.groupUserNumber)+_vm._s(_vm.$t("chatter.group.members"))+" "+_vm._s(_vm.$t("label.referperson"))+": "),_c('span',{on:{"click":function($event){return _vm.jumpOwnerDetails(scope.row.ownerId)}}},[_vm._v(_vm._s(scope.row.ownerName))])])])])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('chatter.group.theLastActivityDate'),"min-width":"80","align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_vm._v(" "+_vm._s(scope.row.lastModifyDateText)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('chatter.group.theMembership'),"min-width":"80","align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{class:scope.row.isStatus != _vm.$t('label.referperson')
                ? 'groupStstusClass'
                : '',on:{"click":function($event){return _vm.openIsStatus(scope.row.isStatus, scope.row.name, scope.row._id)}}},[_vm._v(" "+_vm._s(scope.row.isStatus)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('label.operate'),"min-width":"80","align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-popover',{ref:("popverRef-" + (scope.$index)),attrs:{"placement":"right-start","visible-arrow":false,"width":"113","trigger":"click","popper-class":"groupOperation"},on:{"show":function($event){return _vm.getQueryUserGroupInfo(scope)}}},[(_vm.groupInfoList.loginUserGroupRole === 'manager')?_c('el-card',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.operationLoading),expression:"operationLoading"}],staticClass:"box-card moreMenus"},[_c('ul',[_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.groupInfoList.loginUserGroupRole === 'manager'),expression:"groupInfoList.loginUserGroupRole === 'manager'"}],on:{"click":function($event){return _vm.editGroupDetails(
                      scope.row.name,
                      scope.row.description,
                      scope.row.groupType,
                      scope.row.ownerId,
                      scope.row._id
                    )}}},[_vm._v(" "+_vm._s(_vm.$t("pagecreator_page_button_edit"))+" ")]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.userId == scope.row.ownerId),expression:"userId == scope.row.ownerId"}],on:{"click":function($event){return _vm.deleteGroup(scope.row.name, scope.row._id)}}},[_vm._v(" "+_vm._s(_vm.$t("component_setup_tabs_label_delete"))+" ")])])]):_vm._e(),_c('svg',{staticClass:"img-style",attrs:{"slot":"reference","aria-hidden":"true"},slot:"reference"},[_c('use',{attrs:{"href":"#icon-xiala"}})])],1)]}}])})],1),_c('el-pagination',{attrs:{"page-sizes":[5, 10, 20, 50],"layout":"total, sizes, prev, pager, next, jumper","total":_vm.totalnum},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1),_c('ThisPeopleState',{attrs:{"titleName":_vm.groupName,"isStatus":_vm.isStatus,"thisPeopleStateTo":_vm.thisPeopleStateTo},on:{"thisPeopleStateCancel":_vm.thisPeopleStateCancel,"thisPeopleStateConfirm":_vm.thisPeopleStateConfirm}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }