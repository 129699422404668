<!--
  编辑小组弹框
-->
<template>
  <el-dialog
    :visible="eidtGroupTo"
    :title="$t('label.modify')"
    width="552px"
    :before-close="editGroupCancel"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
  >
    <div class="dialog-body">
      <el-form
        label-position="right"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
      >
        <!-- 小组名称 -->
        <el-form-item :label="$t('label_chatter_name')" prop="groupName">
          <el-input v-model="ruleForm.groupName"></el-input>
        </el-form-item>
        <!-- 备注 -->
        <el-form-item :label="$t('label.weixin.group.description')">
          <el-input type="textarea" v-model="ruleForm.desc"></el-input>
        </el-form-item>
        <!-- 所有人 -->
        <div class="findForm">
          <div class="elForm">
            <el-form-item :label="$t('label.referperson')" prop="peopleName">
              <el-input disabled v-model="ruleForm.peopleName"></el-input>
            </el-form-item>
          </div>
        </div>
        <!-- 访问类型 -->
        <el-form-item :label="$t('label.weixin.group.type')" prop="resource">
          <el-radio-group v-model="ruleForm.resource">
            <el-radio label="public" value="public">{{
              $t("label.customsetting.visible.public")
            }}</el-radio>
            <el-radio label="private" value="private">{{
              $t("label.useonly")
            }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="editGroupCancel">{{
        $t("button_partner_cancel")
      }}</el-button>
      <el-button type="primary" @click="editGroupConfirm('ruleForm')">{{
        $t("label.confirm")
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    eidtGroupTo: {
      type: Boolean,
      default: false,
    },
    // 所有人名称
    username: {
      type: String,
      default: "",
    },
    // 小组名称
    nowGroupName: {
      type: String,
      default: "",
    },
    // 小组备注
    nowGroupDescription: {
      type: String,
      default: "",
    },
    // 小组类型
    nowGroupGroupType: {
      type: String,
      default: "",
    },
  },
  watch: {
    nowGroupName: function () {
      this.ruleForm.groupName = this.nowGroupName;
    },
    nowGroupDescription: function () {
      this.ruleForm.desc = this.nowGroupDescription;
    },
    username: function () {
      this.ruleForm.peopleName = this.username;
    },
    nowGroupGroupType: function () {
      this.ruleForm.resource = this.nowGroupGroupType;
    },
  },

  data() {
    return {
      ruleForm: {
        groupName: "",
        desc: "",
        peopleName: "",
        resource: "",
      },
      rules: {
        groupName: [
          {
            required: true,
            message: this.$i18n.t("lable.product.please.enter"),
            trigger: "blur",
          },
        ],
        resource: [
          {
            required: true,
            message: this.$i18n.t("label.select.please"),
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    editGroupCancel() {
      this.$emit("editGroupCancel", this.ruleForm);
    },
    editGroupConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit("editGroupConfirm", this.ruleForm);
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-body {
  padding: 4px 0px !important;
  ::v-deep .el-form {
    margin-top: 10px;
    .el-form-item {
      &:last-of-type {
        margin-bottom: 0px;
      }
      // label
      .el-form-item__label {
        min-width: 68px !important;
      }
      // 内容
      .el-form-item__content {
        margin-left: 90px !important;
        width: 72%;
      }
    }
    .findForm {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 22px;
      .elForm {
        width: 88%;
        .el-form-item {
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>
