import axios from "@/config/httpConfig";

//删除评论
export function removeMicroComment(data) {
  return axios.post('/ccchat/microPost/removeMicroComment', data)
}
// 获取CCChat小组列表
export function queryUserGroups(data) {
  return axios.post("/ccchat/usergroup/queryUserGroups", data);
}
// 获取CCChat小组详细信息
export function queryUserGroupInfo(data) {
  return axios.post("/ccchat/usergroup/queryUserGroupInfo", data);
}
// 文件-获取列表
export function QueryList(data) {
  return axios.post('/file/queryList', data)
}

//添加CCChat小组成员
export function joinGroup(data) {
  return axios.post("ccchat/usergroup/joinGroup", data);
}
// 删除CCChat小组
export function deleteUserGroup(data) {
  return axios.post("ccchat/usergroup/deleteUserGroup", data);
}
//获取域名
export function geturl(data) {
  return axios.post('/report/getExportReportURL', data)
}
// 获取CCChat小组成员和专用组申请成员
export function queryGroupNumberAndApply(data) {
  return axios.post('ccchat/usergroup/queryGroupNumberAndApply', data)
}
// 删除CCChat小组成员(退出小组)
export function removeGroupMember(data) {
  return axios.post('ccchat/usergroup/removeGroupMember', data)
}
// 添加CCChat小组最近查看
export function addRecent(data) {
  return axios.post('ccchat/usergroup/addRecent', data)
}
// 更新CCChat小组
export function updateUserGroupInfo(data) {
  return axios.post('ccchat/usergroup/updateUserGroupInfo', data)
}
//新增CCChat小组
export function addUserGroupInfo(data) {
  return axios.post('ccchat/usergroup/addUserGroupInfo', data)
}
//查询CCChat小组用户详细信息
export function queryUser(data) {
  return axios.post('ccchat/usergroup/queryUser', data)
}
//修改CCChat小组成员角色
export function changeUserRole(data) {
  return axios.post('ccchat/usergroup/changeUserRole', data)
}
// 查询CCChat小组成员
export function queryGroupManager(data) {
  return axios.post('ccchat/usergroup/queryGroupManager', data)
}
//上传小组头像
export function uploadGroupImage(data) {
  return axios.post('ccchat/usergroup/uploadGroupImage', data)
}
// 获取当前状态是否有发送全体小组成员电子邮件的权限
export function getSendEmailToGroupMemberPermission(data) {
  return axios.post("/ccchat/usergroup/getSendEmailToGroupMemberPermission", data);
}

//查询帖子
export function getCCChats(data) {
  return axios.post('/ccchat/microPost/getCCChats', data)
}

// 删除微贴
export function GetCcchatMicroPostRemoveMicroPost(data) {
  return axios.post('/ccchat/microPost/removeMicroPost', data)
}

// 更新帖子
export function GetCcchatMicroPostRUpdateMicroPost(data) {
  return axios.post('/ccchat/microPost/updateMicroPost', data)
}

//点赞帖子
export function praiseFeed(data) {
  return axios.post('/ccchat/microPost/praiseFeed', data)
}

//@用户列表
export function queryAtUsers(data) {
  return axios.post('/ccchat/microPost/queryAtUsers', data)
}

//发布帖子评论或回复
export function addMicroPostComments(data) {
  return axios.post('/ccchat/microPost/addMicroPostComments', data)
}

//评论帖子 喜欢（点赞）留言或评论
export function praiseComment(data) {
  return axios.post('/ccchat/microPost/praiseComment', data)
}

//投票帖子
export function changePolls(data) {
  return axios.post('/ccchat/microPost/changePolls', data)
}

// 收藏帖子
export function favoriteFeed(data) {
  return axios.post('/ccchat/microPost/favoriteFeed', data)
}

//发布普通帖子
export function addMicroPost(data) {
  return axios.post('/ccchat/microPost/addMicroPost', data)
}

// 发表文件帖子
export function addMicroPostD(data) {
  return axios.post('/ccchat/microPost/addMicroPostD', data)
}

//  分享帖子 
export function shareMicroPost(data) {
  return axios.post('/ccchat/microPost/shareMicroPost', data)
}

//获取文件大小限制
export function queryLimit(data) {
  return axios.post('/file/queryLimit', data)
}

// 文件预览
export function viewFile(data) {
  return axios.post('file/preview', data)
}