import axios from "@/config/httpConfig";

//同事圈主页

// 获取CCChat小组列表
export function queryUserGroups(data) {
  return axios.post("/ccchat/usergroup/queryUserGroups", data);
}
// 获取CCChat小组详细信息
export function queryUserGroupInfo(data) {
  return axios.post("/ccchat/usergroup/queryUserGroupInfo", data);
}
//添加CCChat小组成员
export function joinGroup(data) {
  return axios.post("ccchat/usergroup/joinGroup", data);
}
// 删除CCChat小组
export function deleteUserGroup(data) {
  return axios.post("ccchat/usergroup/deleteUserGroup", data);
}
//获取域名
export function geturl(data){
  return axios.post('/report/getExportReportURL',data)
}
// 获取CCChat小组成员和专用组申请成员
export function queryGroupNumberAndApply(data){
  return axios.post('ccchat/usergroup/queryGroupNumberAndApply',data)
}
// 删除CCChat小组成员(退出小组)
export function removeGroupMember(data){
  return axios.post('ccchat/usergroup/removeGroupMember',data)
}
// 添加CCChat小组最近查看
export function addRecent(data){
  return axios.post('ccchat/usergroup/addRecent',data)
}
// 更新CCChat小组
export function updateUserGroupInfo(data){
  return axios.post('ccchat/usergroup/updateUserGroupInfo',data)
}
//新增CCChat小组
export function addUserGroupInfo(data){
  return axios.post('ccchat/usergroup/addUserGroupInfo',data)
}
//查询CCChat小组用户详细信息
export function queryUser(data){
  return axios.post('ccchat/usergroup/queryUser',data)
}
//修改CCChat小组成员角色
export function changeUserRole(data){
  return axios.post('ccchat/usergroup/changeUserRole',data)
}
// 查询CCChat小组成员
export function queryGroupManager(data){
  return axios.post('ccchat/usergroup/queryGroupManager',data)
}
//上传小组头像
export function uploadGroupImage(data){
  return axios.post('ccchat/usergroup/uploadGroupImage',data)
}
// 获取当前状态是否有发送全体小组成员电子邮件的权限
export function getSendEmailToGroupMemberPermission(data) {
  return axios.post("/ccchat/usergroup/getSendEmailToGroupMemberPermission", data);
}