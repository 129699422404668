<!--
  小组列表顶部组件
-->
<template>
  <div class="groupObjectListTop">
    <div class="left_info">
      <div>{{ $t("chatter.group.name") }}</div>
      <div class="listTop-name">{{ isactiveLabel }}</div>
      <div>{{ totalSize }}个小组</div>
    </div>
    <div class="right_search_butt">
      <el-input
        @keyup.enter.native="searchEnter"
        v-model="form.keyWord"
        :suffix-icon="form.keyWord ? '' : 'el-icon-search'"
        :placeholder="$t('label_tabpage_findz') + isactiveLabel + '...'"
        class="input-with-select"
        :clearable="true"
      >
        <div slot="prepend">{{ $t("chatter.group.groupName") }}</div>
      </el-input>
      <div class="all_butts">
        <el-button type="primary" size="mini" @click="addNewGroup">{{
          $t("vue_label_chatter_label_new_team")
        }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    // 小组总数量
    totalSize: {
      type: Number,
      default: 0,
    },
    //菜单
    isactiveLabel: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      form: {
        keyWord: "", //搜索数据
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 回车搜索
    searchEnter() {
      this.$emit("searchEnter", this.form.keyWord);
    },
    // 新建小组
    addNewGroup() {
      this.$emit("addNewGroup");
    },
  },
  mounted() {},
};
</script>
<style lang='scss' scoped>
.groupObjectListTop {
  background: #f8f8f8;
  padding: 10px;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #cbc6c6;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  .listTop-name {
    font-size: 16px;
    font-weight: bold;
  }
  .left_info {
    color: #666666;
    display: flex;
    flex-direction: column;
    color: #222222;
  }
  .right_search_butt {
    display: flex;
    align-items: center;

    ::v-deep .el-input {
      font-size: 12px;
      margin-right: 12px;
    }
    ::v-deep .el-input__inner {
      height: 30px;
      line-height: 30px;
      width: 270px !important;
    }
    ::v-deep .el-input__icon {
      height: 30px;
      line-height: 30px;
    }
    ::v-deep .el-input-group__prepend {
      .el-input__inner {
        width: 120px !important;
      }
    }

    ::v-deep .input-with-select .el-input-group__prepend {
      background-color: #fff;
    }
    .all_butts {
      display: flex;
    }
  }
}
</style>