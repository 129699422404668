<!-- 小组列表页右侧表格 -->
<template>
  <div class="groupObjectListRightTable">
    <div class="list_table">
      <el-table
        :data="allGroupDataArr"
        height="calc(100% - 66px)"
        v-loading="loading"
      >
        <!-- 小组名称 -->
        <el-table-column
          :label="$t('chatter.group.groupName')"
          min-width="80"
          align="left"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div class="groupContent">
              <!-- 小组头像 -->
              <div class="groupImg">
                <img
                  :src="`${imgUrlCookie}/chatterGroup.action?m=showGroupImage&id=${scope.row._id}&binding=${tokenCookie}`"
                  alt=""
                />
              </div>
              <!-- 小组信息 -->
              <div class="groupWord">
                <div class="word_style">
                  <span
                    :class="
                      scope.row.isStatus === $t('label.referperson') ||
                      scope.row.isStatus === $t('label_tabpage_exitz')
                        ? ''
                        : 'groupNameClass'
                    "
                    @click="jumpGroupDetails(scope.row.isStatus, scope.row._id)"
                    >{{ scope.row.name }}</span
                  >
                  ({{ scope.row.groupTypeValue }})
                </div>
                <div class="word_style">
                  {{ scope.row.groupUserNumber
                  }}{{ $t("chatter.group.members") }}
                  {{ $t("label.referperson") }}:
                  <span @click="jumpOwnerDetails(scope.row.ownerId)">{{
                    scope.row.ownerName
                  }}</span>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- 上次活动日期 -->
        <el-table-column
          :label="$t('chatter.group.theLastActivityDate')"
          min-width="80"
          align="left"
        >
          <template slot-scope="scope">
            <div>
              {{ scope.row.lastModifyDateText }}
            </div>
          </template>
        </el-table-column>
        <!-- 成员关系 -->
        <el-table-column
          :label="$t('chatter.group.theMembership')"
          min-width="80"
          align="left"
        >
          <template slot-scope="scope">
            <div
              @click="
                openIsStatus(scope.row.isStatus, scope.row.name, scope.row._id)
              "
              :class="
                scope.row.isStatus != $t('label.referperson')
                  ? 'groupStstusClass'
                  : ''
              "
            >
              {{ scope.row.isStatus }}
            </div>
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column
          :label="$t('label.operate')"
          min-width="80"
          align="left"
        >
          <template slot-scope="scope">
            <el-popover
              placement="right-start"
              :visible-arrow="false"
              width="113"
              trigger="click"
              popper-class="groupOperation"
              @show="getQueryUserGroupInfo(scope)"
              :ref="`popverRef-${scope.$index}`"
            >
              <el-card
                class="box-card moreMenus"
                v-if="groupInfoList.loginUserGroupRole === 'manager'"
                v-loading="operationLoading"
              >
                <ul>
                  <!-- 编辑小组 -->
                  <li
                    v-show="groupInfoList.loginUserGroupRole === 'manager'"
                    @click="
                      editGroupDetails(
                        scope.row.name,
                        scope.row.description,
                        scope.row.groupType,
                        scope.row.ownerId,
                        scope.row._id
                      )
                    "
                  >
                    {{ $t("pagecreator_page_button_edit") }}
                  </li>
                  <!-- 删除小组 -->
                  <li
                    v-show="userId == scope.row.ownerId"
                    @click="deleteGroup(scope.row.name, scope.row._id)"
                  >
                    {{ $t("component_setup_tabs_label_delete") }}
                  </li>
                </ul>
              </el-card>
              <svg class="img-style" aria-hidden="true" slot="reference">
                <use href="#icon-xiala"></use>
              </svg>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[5, 10, 20, 50]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalnum"
      ></el-pagination>
    </div>
    <!-- 当前用户与小组的状态弹框 -->
    <ThisPeopleState
      :titleName="groupName"
      :isStatus="isStatus"
      :thisPeopleStateTo="thisPeopleStateTo"
      @thisPeopleStateCancel="thisPeopleStateCancel"
      @thisPeopleStateConfirm="thisPeopleStateConfirm"
    />
  </div>
</template>

<script>
//api
import {
  joinGroup, //添加CCChat小组成员
  addRecent, // 添加小组到最近查看
  removeGroupMember, //删除CCChat小组成员(退出小组)
  queryUserGroupInfo, //获取CCChat小组详细信息
} from "../../api.js";
import ThisPeopleState from "@/views/colleagueCircle/components/dynamic-groupList/ThisPeopleState";
import VueCookies from "vue-cookies";
export default {
  components: {
    ThisPeopleState,
  },
  data() {
    return {
      thisPeopleStateTo: false,
      tokenCookie: "", //token
      imgUrlCookie: "", //域名
      isStatus: "", //当前用户与小组的关系
      groupName: "", //小组name
      groupId: "", //小组ID
      groupInfoList: {},
      getInfoId: "", //获取详细信息时小组的ID
      addRecentId: "", //添加到最近查看时的小组ID
      operationLoading: false, //获取"操作权限"时加loading
    };
  },
  props: {
    //小组列表
    allGroupDataArr: {
      type: Array,
      default: () => ([]),
    },
    loading: {
      //切换小组列表时获取的动态loading
      type: Boolean,
      default: false,
    },
    //当前登录用户的ID
    userId: {
      type: String,
      default: "",
    },
    //小组总数
    totalnum: {
      type: Number,
      default: 0,
    },
  },
  watch: {},
  mounted() {
    this.tokenCookie = this.$CCDK.CCToken.getToken(); //token
    this.imgUrlCookie = VueCookies.get("domainName"); //获取域名
  },
  methods: {
    //获取小组的详细信息
    async getQueryUserGroupInfo(scope) {
      this.operationLoading = true;
      this.getInfoId = scope.row._id;
      let groupInfoRes = await queryUserGroupInfo({
        groupId: this.getInfoId,
      });
      this.groupInfoList = groupInfoRes.data.list[0];
      
      if (this.groupInfoList.loginUserGroupRole != "manager") {
        this.$refs[`popverRef-${scope.$index}`].doClose(); // 隐藏弹框
        this.$message("无操作权限");
      }
      this.operationLoading = false;
    },
    //切换每页显示条数,5,10,
    handleSizeChange(val) {
      this.$emit("handleSizeChange", val);
    },

    //切换当前显示页数,1,2,3,4
    handleCurrentChange(val) {
      this.$emit("handleCurrentChange", val);
    },
    //点击小组名称跳转到小组详情（小组详情页）
    async jumpGroupDetails(isStatus, id) {
      this.addRecentId = id;
      if (
        isStatus == this.$i18n.t("label.referperson") ||
        isStatus == this.$i18n.t("label_tabpage_exitz")
      ) {
        // 把小组添加到最近查看
        await addRecent({
          groupId: this.addRecentId,
        });
        this.$router.push({
          path: `/groupObject/groupDetails`,
          query: {
            id: id,
            isStatus: isStatus,
          },
        });
      }
    },
    //点击所有人跳转到对应的详情（用户详情页面）
    jumpOwnerDetails(id) {
      this.$router.push(`/commonObjects/detail/${id}/DETAIL`);
    },

    /*打开当前用户与小组的关系的弹框
      申请加入--取消申请--退出小组--加入小组 */
    openIsStatus(isStatus, groupName, groupId) {
      if (isStatus != this.$i18n.t("label.referperson")) {
        this.isStatus = isStatus;
        this.groupName = groupName;
        this.groupId = groupId;
        this.thisPeopleStateTo = true;
      }
    },
    //取消或关闭弹框
    thisPeopleStateCancel() {
      this.thisPeopleStateTo = false;
    },
    //申请加入
    async applyToGroupOne() {
      await joinGroup({
        groupId: this.groupId,
        userId: this.userId,
        joinType: "apply",
      });
    },
    //取消申请
    async rejectedToGroupOne() {
      await joinGroup({
        groupId: this.groupId,
        userId: this.userId,
        joinType: "rejected",
      });
    },
    //退出小组
    async exitGroup() {
      await removeGroupMember({
        groupId: this.groupId,
        userId: this.userId,
      });
    },
    //当前用户与小组的状态弹框--确定
    async thisPeopleStateConfirm() {
      this.thisPeopleStateTo = false;
      switch (this.isStatus) {
        case this.$i18n.t("chatter.group.applyToJoin"): //申请加入
          await this.applyToGroupOne();
          break;
        case this.$i18n.t("chatter.group.cancelRequest"): //取消申请
          await this.rejectedToGroupOne();
          break;
        case this.$i18n.t("label_tabpage_exitz"): //退出小组
          await this.exitGroup();
          break;
      }
      await this.$bus.$emit("getQueryUserGroupsBus");
    },
    // 编辑小组
    editGroupDetails(name, description, groupType, ownerId, id) {
      this.$emit("editGroupDetails", name, description, groupType, ownerId, id);
    },
    // 删除小组
    deleteGroup(name, id) {
      this.$emit("deleteGroup", name, id);
    },
  },
};
</script>
<style lang='scss' scoped>
@import "@/style/table.scss";
.groupObjectListRightTable {
  width: calc(100% - 250px);
  background-color: #fff;
  .list_table {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    ::v-deep .el-table {
      height: calc(100% - 66px);
      overflow-y: auto;
      // 小组头像,名称，所有人等...
      .groupContent {
        display: flex;
        align-items: center;
        .groupImg {
          img {
            width: 28px;
            height: 28px;
          }
        }
        .groupWord {
          margin-left: 10px;
          .word_style {
            .groupNameClass {
              color: #bbb;
              cursor: default;
            }
            span {
              color: #006dcc;
              cursor: pointer;
            }
          }
        }
      }
      // 与小组的关系
      .groupStstusClass:hover {
        cursor: pointer;
        color: #006dcc;
      }
      .img-style {
        width: 19px;
        height: 19px;
      }
    }
    ::v-deep .el-table::before {
      background-color: transparent !important;
    }
    //表格翻页
    .el-pagination {
      height: 66px;
      padding-left: 15px;
      background: #fff;
      box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.15);
      border-radius: 0 0 3px 3px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
</style>

<style lang='scss'>
.el-popover.groupOperation {
  padding: 0 !important;
  // margin: 0 0 0 90px;
  min-width: 113px;
  ::v-deep img {
    width: 15px !important;
    height: 15px !important;
  }
  .moreMenus {
    // width: 113px;
    .el-card__body {
      padding: 10px;
      ol,
      ul,
      dl {
        margin-bottom: 0;
      }
      li {
        height: 30px;
        line-height: 30px;
        &:hover {
          color: #006dcc;
          cursor: pointer;
        }
        .el-button {
          color: #303133;
        }
        .el-button.is-disabled.el-button--text {
          color: #c0c4cc;
        }
      }
    }
  }
}
</style>